import CRUDFactory from './crud_factory';
import { postRequest, deleteRequest, putRequest } from '../api/client';

type InternalContract = {
  id: number;
  contractNumber: string;
  status: string;
  updatedAt: string;
  startDate: string;
  endDate: string;
  contractTerm: string;
  numberOfTimesRenewed: number;
  hasOut: boolean;
  rrSavingsPercentage: number;
  autoRenewalWindow: number;
  autoRenewalTerm: number;
  modComments: string;
  mod_comments: string;
  customerSignedMmaFileUrl: string;
  customerSignedMmaFileName: string;
  contractDocumentFileUrl: string;
  contractDocumentFileName: string;
  confirmationLetterFileUrl: string;
  confirmationLetterFileName: string;
  engagement: {
    id: number;
    engagementType: string;
  };
};
export type DeleteInternalContractResponse = {
  success: boolean;
  message: string;
};
export type UpdateInternalContractResponse = InternalContract;
export type GetInternalContractsResponse = InternalContract[];
export type CreateContractFromEngagementResponse = {
  job_id?: string;
  message?: string;
};
interface InternalContractFactoryType extends Omit<CRUDFactory, 'update'> {
  get(params?: {}): Promise<GetInternalContractsResponse>;
  delete(id: number, options?: { notify: boolean }): Promise<DeleteInternalContractResponse>;
  update(id: number | string, data: {}): Promise<UpdateInternalContractResponse>;
  createContractFromEngagement: (data: {}, engagementId: number) => Promise<CreateContractFromEngagementResponse>;
};
class InternalContractFactory extends CRUDFactory {
  engagementUrl: string;
  controllerUrl: string;
  constructor(props: any) {
    super(props);
    this.engagementUrl = '/engagements';
    this.controllerUrl = '/internal_contracts';
  }

  createContractFromEngagement(data: {}, engagementId: number) {
    return postRequest(
      `${this.engagementUrl}/${engagementId}/upload_mma`,
      data
    );
  }

  // @ts-ignore
  update(id: number | string, data: {}) {
    return putRequest(`${this.controllerUrl}/${id}`, data);
  }

  delete(id: number, options = { notify: true }) {
    return deleteRequest(`${this.controllerUrl}/${id}`, options);
  }
}

export default new InternalContractFactory({
  baseUrl: '/api/private/v4/internal_contracts/',
}) as InternalContractFactoryType;
